export const CreationPageResourcesEN: ICreationPageResources = {
  title: "Create New Task",
  button: "TASK PREVIEW",
  general: "TASK DETAILS",
  productImages: "TASK IMAGES",
  previewPage: {
    description: "Description: ",
    location: "Location: ",
    phoneNumber: "Phone Number: ",
    category: "Category: ",
    loading: "Offer is being created...",
  },
  modal: {
    title: "Successfull!",
    text: "The task has been successfully created.",
    button: "Done",
  },
  errors: {
    creation: "Error creating offer",
  },
  fields: { 
    postalCode: {
      label: "ZIP Code",
      name: "postalCode",
      error: "Please enter a valid zip code.",
      validation: "Wrong ZIP code.", 
          zeroOption: "Please select your city first.",
      placeholder: "Please select your postal code.", 
    },
    country: {
      label: "Country",
      name: "country",
      error: "Please select a country.",
      validation: "Wrong country.",
      countries: {
        AUSTRIA: "Austria",
      },
    },
    cityId: {
      label: "City",
      name: "cityId",
      error: "Please select a city.",
      validation: "Wrong city.",
      cities: {
        AUSTRIA: {
          VIENNA: "Vienna",
        },
      },
    },
    name: "User: ",
    title: {
      name: "title",
      label: "Title",
      error: "Please enter a title.",
    },
    description: {
      name: "description",
      label: "Description",
      error: "Please enter a description.",
    },
    category: {
      name: "category",
      label: "Category",
      error: "Please select the category.",
    },
    images: {
      name: "images",
      label: "Upload images",
      error: "Please upload an image.",
    },
    showMobile: {
      label: "Show phone number",
    },
  },
};

export const CreationPageResourcesDE: ICreationPageResources = {
  title: "Neuer Aufgabe Erstellen",
  button: "AUFTRAG VORSCHAU",
  general: "AUFTRAG DETAILS",
  productImages: "AUFTRAG BILDER",
  previewPage: {
    description: "Beschreibung: ",
    location: "Standort: ",
    phoneNumber: "Telefonnummer: ",
    category: "Kategorie: ",
    loading: "Der Auftrag wird erstellt...",
  },
  errors: {
    creation: "Error bei der Erstellung des Auftrages.",
  },
  modal: {
    title: "Erfolgreich!",
    text: "Der Auftrag wurde erfolgreich erstellt.",
    button: "Erledigt",
  },
  fields: {
    postalCode: {
      zeroOption: "Bitte wählen Sie erst die Stadt aus.",
      placeholder: "Bitte wählen Sie Ihre Postleitzahl aus.",
      label: "PLZ", 
      name: "postalCode",
      error: "Bitte geben Sie eine gültige PLZ ein.",
      validation: "Falsche PLZ.",   
    },
    country: {
      label: "Land",
      name: "country",
      error: "Bitte wählen Sie ein Land aus.",
      validation: "Falsches Land.",
      countries: {
        AUSTRIA: "Österreich",
      },
    },
    cityId: {
      label: "Stadt",
      name: "cityId",
      error: "Bitte wählen Sie eine Stadt aus.",
      validation: "Falsche Stadt.",
      cities: {
        AUSTRIA: {
          VIENNA: "Wien",
        },
      },
    },
    name: "Benutzer: ",
    title: {
      name: "title",
      label: "Titel",
      error: "Bitte geben Sie einen Titel ein.",
    },
    description: {
      name: "description",
      label: "Beschreibung",
      error: "Bitte geben Sie eine Beschreibung ein.",
    },
    category: {
      name: "category",
      label: "Kategorie",
      error: "Bitte wählen Sie die Kategorie aus.",
    },
    images: {
      name: "images",
      label: "Bilder hochladen",
      error: "Bitte laden Sie Bild(er) hoch.",
    },
    showMobile: {
      label: "Handynummer anzeigen",
    },
  },
};

interface PreviewPage {
  description: string;
  location: string;
  phoneNumber: string;
  category: string;
  loading: string;
}

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface ValidationField {
  label: string;
  name: string;
  error: string;
  validation?: string;
}

interface ValidationFieldZip {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder: string;
  zeroOption: string;
}

interface CountryField extends ValidationField {
  countries: Record<string, string>;
}

interface CityField extends ValidationField {
  cities: Record<string, Record<string, string>>;
}

interface Fields {
  postalCode: ValidationFieldZip;
  country: CountryField;
  cityId: CityField;
  name: string;
  title: ValidationField;
  description: ValidationField;
  category: ValidationField;
  images: ValidationField;
  showMobile: { label: string };
}

interface ErrorFields {
  creation: string;
}

export interface ICreationPageResources {
  title: string;
  button: string;
  general: string;
  productImages: string;
  previewPage: PreviewPage;
  modal: Modal;
  fields: Fields;
  errors: ErrorFields;
}
