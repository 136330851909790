import styled from "styled-components";

const DynamicParagraph = styled.p`
  padding: 10px;
  color: ${(props) => props.theme.mainColor};
  font-size: 1.3rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const HomeResourcesEN: IHomeResources = {
  title: () => (
    <div style={{ border: "5px solid #68CF44" }}>
      <DynamicParagraph>
        <b>
          Dear Users, <br />
          Welcome to servi.co, a platform that connects service providers with
          individuals or businesses that need these services. For more about our
          values, visit our About Us page. <br />
          We are currently inviting service providers to join servi.co. During
          our promotional period, all new service providers will enjoy free,
          no-obligation membership until 28.02.2025. After that, you decide
          whether to continue using servi.co. The promotional period ends on
          31.12.2024, after which new service providers will receive two months
          of free membership.
          <br /> By creating an account, you’ll gain full access to the platform
          and our pricing overview for when your free membership ends. This is a
          great opportunity to grow your business and build your reputation.
          servi.co offers user-friendly experience, transparent pricing with no
          hidden costs, helping small and medium-sized businesses grow while
          giving clients access to quality services at competitive prices. Join
          us today and take the first step toward enhancing your business and
          professional success. <br />
          Best of luck!
        </b>
      </DynamicParagraph>
    </div>
  ),
  latestTitle: "Latest tasks",
  mostPopular: "Most popular tasks",
  messageBtn: "Open",
};

export const HomeResourcesDE: IHomeResources = {
  title: () => (
    <div style={{ border: "5px solid #68CF44" }}>
      <DynamicParagraph>
        <b>
          Liebe Nutzerinnen und Nutzer, <br />
          Willkommen bei servi.co, einer Plattform, die Dienstleistungsanbieter
          mit Privatpersonen und Unternehmen zusammenbringt, die diese Services
          benötigen. Mehr über unsere Werte erfahren Sie auf unserer Über
          uns-Seite. <br />
          Derzeit laden wir Dienstleister ein, sich bei servi.co zu
          registrieren. Während unseres Aktionszeitraums erhalten alle neuen
          Dienstleister eine kostenlose und unverbindliche Mitgliedschaft bis
          zum 28.02.2025. Danach können Sie entscheiden, ob Sie weiterhin dabei
          bleiben möchten. Der Aktionszeitraum endet am 31.12.2024, danach
          erhalten neue Dienstleister zwei Monate kostenlose Mitgliedschaft.{" "}
          <br />
          Wenn Sie ein Konto erstellen, erhalten Sie vollen Zugang zur Plattform
          sowie zu den Preismodellen, die nach Ablauf Ihrer kostenlosen
          Mitgliedschaft gelten. Dies ist eine hervorragende Gelegenheit, Ihr
          Geschäft auszubauen und Ihren Ruf zu stärken. <br />
          Servi.co bietet eine benutzerfreundliche Plattform, transparente
          Preise und keine versteckten Gebühren, um kleinen und mittleren
          Unternehmen beim Wachsen zu helfen. Gleichzeitig erhalten Ihre Kunden
          Zugang zu hochwertigen Dienstleistungen zu wettbewerbsfähigen Preisen.
          Werden Sie noch heute Mitglied und machen Sie den ersten Schritt zur
          Steigerung Ihres geschäftlichen und beruflichen Erfolgs. Viel Erfolg!
        </b>
      </DynamicParagraph>
    </div>
  ),
  messageBtn: "Öffnen",
  latestTitle: "Aufträge",
  mostPopular: "Beliebtesten Aufträge",
};

export interface IHomeResources {
  title: () => React.ReactElement;
  messageBtn: string;
  latestTitle: string;
  mostPopular: string;
}
