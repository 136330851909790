export const OfferPageResourcesEN: IOfferPageResources = {
  title: "Creation Page",
  button: "Task preview",
  general: "GENERAL",
  productImages: "TASK IMAGES",
  boostTypeLabel: "Boost Type",
  previewPage: {
    description: "Description: ",
    location: "Location: ",
    phoneNumber: "Phone Number: ",
    category: "Category: ",
  },
  sendMessage: {
    button: "Contact user",
    label: "Message",
    placeholder: "Enter your message.",
    error: "Please enter a message.",
    name: "message",
    button2: "Send",
  },
  modal: {
    title: "",
    text: "Do you want to delete the task?",
    buttonYes: "Delete",
    buttonNo: "Back",
  },
  errors: {
    notFound: "The requested task could not been found.",
    offer: "Task was not found.",
    message: "The message could not be sent.",
  },
  fields: {
    title: {
      name: "title",
      label: "Title",
      error: "Please enter a title.",
    },
    description: {
      name: "description",
      label: "Description",
      error: "Please enter a description.",
    },
    boostType: {
      name: "boostType",
      label: "Boost Type",
      error: "Please enter the boost type.",
      premium: "Premium",
      basic: "Basic",
    },
    category: {
      name: "category",
      label: "Category",
      error: "Please enter the category.",
    },
    images: {
      name: "images",
      label: "Upload images",
      error: "Please upload image(s).",
    },
  },
  buttons: {
    edit: "Edit task",
    delete: "Delete task",
    message: "Message task",
  },
};

export const OfferPageResourcesDE: IOfferPageResources = {
  title: "Erstellungsseite",
  button: "Auftragsvorschau",
  general: "GENERELL",
  productImages: "AUFTRAGSBILDER",
  boostTypeLabel: "Boost Typ",
  previewPage: {
    description: "Beschreibung: ",
    location: "Standort: ",
    phoneNumber: "Telefonnummer: ",
    category: "Kategorie: ",
  },
  sendMessage: {
    button: "Benutzer kontaktieren",
    label: "Nachricht",
    placeholder: "Gib deine Nachricht ein.",
    error: "Bitte geben Sie eine Nachricht ein.",
    name: "message",
    button2: "Schicken",
  },
  modal: {
    title: "",
    text: "Möchten Sie den Auftrag löschen?",
    buttonYes: "Löschen",
    buttonNo: "Zurück",
  },
  errors: {
    notFound: "Der angeforderte Auftrag konnte nicht gefunden werden.",
    offer: "Auftrag wurde nicht gefunden.",
    message: "Die Nachricht konnte nicht geschickt werden.",
  },
  fields: {
    title: {
      name: "title",
      label: "Titel",
      error: "Bitte geben Sie einen Titel ein.",
    },
    description: {
      name: "description",
      label: "Beschreibung",
      error: "Bitte geben Sie eine Beschreibung ein.",
    },
    boostType: {
      name: "boostType",
      label: "Boost Typ",
      error: "Bitte geben Sie einen Boost-Typ ein.",
      premium: "Premium",
      basic: "Basic",
    },
    category: {
      name: "category",
      label: "Kategorie",
      error: "Bitte geben Sie die Kategorie ein.",
    },
    images: {
      name: "images",
      label: "Bilder hochladen",
      error: "Bitte laden Sie Bild(er) hoch.",
    },
  },
  buttons: {
    edit: "Auftrag bearbeiten",
    delete: "Auftrag löschen",
    message: "Nachrichtenauftrag",
  },
};

interface Modal {
  title: string;
  text: string;
  buttonYes: string;
  buttonNo: string;
}

interface ErrorMessages {
  notFound: string;
  offer: string;
  message: string;
}

interface FieldValidation {
  name: string;
  label: string;
  error: string;
}

interface BoostTypeField extends FieldValidation {
  premium: string;
  basic: string;
}

interface SendMessage {
  button: string;
  label: string;
  placeholder: string;
  error: string;
  name: string;
  button2: string;
}

interface PreviewPage {
  description: string;
  location: string;
  phoneNumber: string;
  category: string;
}

interface Fields {
  title: FieldValidation;
  description: FieldValidation;
  boostType: BoostTypeField;
  category: FieldValidation;
  images: FieldValidation;
}

interface Buttons {
  edit: string;
  delete: string;
  message: string;
}

export interface IOfferPageResources {
  title: string;
  button: string;
  general: string;
  productImages: string;
  boostTypeLabel: string;
  previewPage: PreviewPage;
  sendMessage: SendMessage;
  modal: Modal;
  errors: ErrorMessages;
  fields: Fields;
  buttons: Buttons;
}
