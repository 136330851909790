export const FaqResourcesEN: IFaqResources = {
  title: "FAQ",
  modal: {
    title: "Success Modal",
    text: "Email sent!",
    button: "Done",
  },
  errors: {
    message: "Email could not be sent!",
  },
  help: [
    {
      id: 1,
      question: "How to join servi.co as a Service Provider?",
      answer: () => (
        <>
          <p>
            You can join servi.co easy and withing few minutes. To register as a
            service provider at servi.co, you will need:
          </p>
          <li>E-mail address</li>
          <li>Phone number</li>
          <br />
          <p>
            When creating the account at servi.co, you need to follow the few
            mandatory steps:
          </p>
          <li>ACCOUNT DETAILS section</li>
          <li>Select SERVICE PROVIDER as the account type.</li>
          <li>Provide a full name.</li>
          <li>
            If you're a company, select "Are you a company?" checkbox and
            provide your company name.
          </li>
          <li>Provide your mobile phone number in the "Phone number" field.</li>
          <li>
            Provide an E-mail address for your account in the "E-mail" field.
            Please note, it should be the E-mail address that you have access to
            as this will be used for the communication between you and the
            platform.
          </li>
          <li>
            Choose a password for your account and enter it in the "Password"
            field.
          </li>
          <li>Repeat the same password in the "Repeat password" field.</li>
          <li>
            Select service categorie(s) that you offer in the "Categories"
            field.
          </li>
          <li>
            Select region(s) where you offer your service(s) in the "Area"
            field.
          </li>
          <li>
            Checkout “I’m over 18+” field. Only adult persons are allowed to use
            the platform.
          </li>
          <li>ADDRESS section</li>
          <li>
            Select the city of your- or your company residence in the "City"
            field.
          </li>
          <li>
            Select your- or your company ZIP code in the "ZIP code" field.
          </li>
          <li>Select "I accept Terms and Conditions" checkbox.</li>
          <br />
          <p>
            All other fields are optional and can be updated anytime under "My
            Account" page.
          </p>
          <li>Finally, click the "Sign up" button.</li>
          <li>
            The last step is to confirm your E-mail address via the link that
            you will receive on the E-mail that you provided during the
            registration. If you don't find the confirmation email in your
            Inbox, please check the Spam mailbox. After you confirm your email,
            you will be succesfully registered at servi.co
          </li>
        </>
      ),
    },
    {
      id: 2,
      question: "How to join servi.co as a Client?",
      answer: () => (
        <>
          <p>
            You can join servi.co easy and withing few minutes. To register as a
            client at servi.co, you will need:
          </p>
          <li>E-mail address</li>
          <li>Phone number</li>
          <br />
          <p>
            When creating the account at Servico, you need to follow these
            mandatory steps:
          </p>
          <li>ACCOUNT DETAILS section</li>
          <li>Select CLIENT as the account type.</li>
          <li>Provide a full name.</li>
          <li>
            Provide an E-mail address for your account in the "E-mail" field.
            Please note, it should be the E-mail address that you have access to
            as this will be used for the communication between you and the
            platform.
          </li>
          <li>
            Choose a password for your account and enter it in the "Password"
            field.
          </li>
          <li>Repeat the same password in the "Repeat password" field.</li>
          <li>
            Checkout “I’m over 18+” field. Only adult persons are allowed to use
            the platform.
          </li>
          <li>ADDRESS section</li>
          <li>Select the city of your residence in the "City" field.</li>
          <li>
            Select the ZIP code of your residence in the "ZIP code" field.
          </li>
          <li>Select "I accept Terms and Conditions" checkbox.</li>
          <br />
          <p>
            All other fields are optional and can be updated anytime under "My
            Account" page.
          </p>
          <li>Finally, click the "Sign up" button.</li>
          <li>
            The last step is to confirm your E-mail address via the link that
            you will receive on the E-mail that you provided during the
            registration. If you don't find the confirmation email in your
            Inbox, please check the Spam mailbox. After you confirm your email,
            you will be succesfully registered at servi.co
          </li>
        </>
      ),
    },
    {
      id: 3,
      question: "How to Create New Task?",
      answer: () => (
        <>
          <p>
            To create a new task, please navigate to New Task” section in the
            navigation bar. When the task creation form is open, it needs the
            following details to be provided:
          </p>
          <li>Title of the task in the "Title" field.</li>
          <li>Short description of the task in the "Description" field.</li>
          <li>Select the task category in the "Category" field.</li>
          <li>
            Select the city where the task should be done in the "City" field.
          </li>
          <li>
            Select the ZIP code where the task should be done in the "ZIP Code"
            field.
          </li>
          <br />
          <p>All other fields are optional.</p>
          <li>
            When the task is ready click the "Task Preview" button to preview
            your task details. If everything is correct you should confirm by
            clicking the "Create Task" button. If you want to go back and edit
            the task, just click the "Edit Task".
          </li>
        </>
      ),
    },
    // {
    //   id: 4,
    //   question: "How to apply for a Task?",
    //   answer: () => (
    //     <>
    //       In order to apply for a task, a service provider should first navigate
    //       to “Browse Tasks” tab in the navigation bar. In the Browse Tasks page,
    //       there are 3 filters (a category, a city and a ZIP code) to be
    //       selected. After choosing the filter, you confirm your selection with
    //       the “Search” button. Following that you’re prompt with the list of
    //       tasks that satisfy your filters. From that list, the service provider
    //       can see the details of each task and contact with the task owner via
    //       message or phone call if available.
    //     </>
    //   ),
    // },
    {
      id: 5,
      question: "How to register a service company?",
      answer: () => (
        <>
          <p>
            If you’re a service provider and you want to register your company,
            we provide you a short summary of where to start. Please be informed
            that the information provide in this text are a result of our search
            and interpretation and that we do not guarantee for the correctness
            of the information. This serves just as a starting point on your
            journey towards your company foundation and we hope it can help to
            acquire the initial information.
          </p>
          If you are just starting with your business, maybe it makes more sense
          that you start as a small business owner (it calls Kleinunternehmen in
          Austria), because it gives you a lot of ease regarding the costs. As
          you advance and make more revenue, you are changing your legal form as
          the company. More details about starting the small business you can
          find under the links:
          <li>
            Gewerbe anmelden - Voraussetzungen zum Gewerbeschein - Österreich
            (finanz.at)
          </li>
          <li>
            Kleinunternehmerregelung 2023 - Kleinunternehmer - Österreich
            (finanz.at)
          </li>
          <br />
          <p>
            At any time, you can also make your free appointment at chamber of
            commerce (Wirtschaftskammer) by calling a free phone number +43 1
            514 50 0 or via email info@wkw.at. At the appointment, you can ask
            any question and discover more details about starting your own
            business.
          </p>
        </>
      ),
    },
    // {
    //   id: 6,
    //   question: "How to leave a review?",
    //   answer: () => (
    //     <>
    //       <p>
    //         As a client, you can leave a review for each service provider based
    //         on your satisfaction regarding the cooperation in terms of
    //         communication, service expertise or service price. To leave a review
    //         there are two options:
    //       </p>
    //       <li>
    //         From the service provider’s Profile Page. Go to the profile page of
    //         the service provider. Click the “Leave Review” button and in the
    //         next page choose the rating and optionally write some review.
    //         Confirm your review with the “Submit Review” button.
    //       </li>
    //       <li>
    //         2. From the Chat section. If you communicated with a service
    //         provider via chat, you can straight from the chat click on a button
    //         “Leave Review” button and in the next page choose the rating and
    //         optionally write some review. Confirm your review with the “Submit
    //         Review” button.
    //       </li>
    //     </>
    //   ),
    // },
    // {
    //   id: 7,
    //   question: "How to report a user?",
    //   answer: () => (
    //     <p>
    //       In case of breaking the communication rules stated in the General
    //       Terms and Condition, you can report another user. To do so, you need
    //       to navigate to the user’s Profile Page and click the “Report User”
    //       button. In the next page, you should provide a few details regarding
    //       the report reason so that we can verify the validity of your complaint
    //       and take the next steps. Confirm your claim with the “Submit Report”
    //       button.
    //     </p>
    //   ),
    // },
    // {
    //   id: 8,
    //   question: "How to block a user?",
    //   answer: () => (
    //     <p>
    //       If you for any reason want to prevent another user from contacting
    //       you, you can but that use on your Block List. For that you need to
    //       navigate to that user’s Profile Page and click the “Block this user”
    //       button. From that moment this user will not be able to contact you if
    //       you keep him or her on your block list.
    //     </p>
    //   ),
    // },
    // {
    //   id: 9,
    //   question: "How to unblock a user?",
    //   answer: () => (
    //     <p>
    //       You can unblock (remove from your block list) a user by navigating to
    //       this user’s Profile Page and clicking on the “Unblock this user”
    //       button. From that moment, this user is again able to contact you.
    //     </p>
    //   ),
    // },
    // {
    //   id: 10,
    //   question: "How to put Task into favorites?",
    //   answer: () => (
    //     <p>
    //       If you’re a service provider, you can save tasks into your favorites
    //       list and contact them later. For this, you need to open a task
    //       description and click on a star icon. To see your favorites list, you
    //       should navigate to the Communication tab in the Navigation bar. Under
    //       the menu in the Communication page, you will have Favorites section
    //       with all the tasks that you saved.
    //     </p>
    //   ),
    // },
    // {
    //   id: 11,
    //   question: "How to remove task from Favorites?",
    //   answer: () => (
    //     <p>
    //       You can remove tasks from the favorites list by navigation to the
    //       Communication tab in the Navigation bar. Under the menu in the
    //       Communication page, you will have Favorites section with all the tasks
    //       that you saved. To remove a task from the list, open the task and
    //       click on the star icon.
    //     </p>
    //   ),
    // },
  ],
  contactUs: {
    email: {
      label: "Email",
      placeholder: "Enter your email",
      error: "Please enter a valid email address",
      name: "email",
      validation: "The entered email address is not valid",
    },
    message: {
      label: "Message",
      placeholder: "Enter your message",
      error: "Please enter a valid message",
      name: "message",
    },
    contact: "Contact us: contact@servi.co",
    button: "Message us",
  },
};

export const FaqResourcesDE: IFaqResources = {
  title: "FAQ",
  modal: {
    title: "Success Modal",
    text: "E-mail geschickt!",
    button: "Erledigt",
  },
  errors: {
    message: "E-mail konnte nicht geschickt werden!",
  },
  help: [
    {
      id: 1,
      question: "Wie komme ich als Dienstleister:innen zu Servico?",
      answer: () => (
        <>
          <p>
            Sie können sich einfach und in wenigen Minuten bei servi.co
            anmelden. Um sich als Dienstleister bei servi.co zu registrieren,
            benötigen Sie:
          </p>
          <li>E-Mail-Adresse</li>
          <li>Telefonnummer</li>
          <br />
          <p>
            Beim Erstellen des Kontos bei servi.co müssen Sie die folgenden
            Pflichtschritte ausführen:
          </p>
          <li>Abschnitt KONTOINFORMATIONEN</li>
          <li>Wählen Sie "DIENSTLEISTER:IN" als Kontotyp.</li>
          <li>Geben Sie einen vollständigen Namen an.</li>
          <li>
            Wenn Sie ein Unternehmen sind, wählen Sie das Kontrollkästchen "Sind
            Sie eine Firma?" und geben Sie den Namen Ihres Unternehmens an.
          </li>
          <li>Geben Sie Ihre Handynummer im Feld "Telefonnummer" an.</li>
          <li>
            Geben Sie eine E-Mail-Adresse für Ihr Konto im Feld "E-Mail" an.
            Bitte beachten Sie, dass es sich um die E-Mail-Adresse handeln
            sollte, auf die Sie Zugriff haben, da diese für die Kommunikation
            zwischen Ihnen und der Plattform verwendet wird.
          </li>
          <li>
            Wählen Sie ein Passwort für Ihr Konto und geben Sie es im Feld
            "Passwort" ein.
          </li>
          <li>
            Wiederholen Sie dasselbe Passwort im Feld "Passwort wiederholen".
          </li>
          <li>
            Wählen Sie im Feld "Kategorien", die Dienstkategorie(n), die Sie
            anbieten.
          </li>
          <li>
            Wählen Sie im Feld "Ort Selektieren", die Region(en), in denen Sie
            Ihre Dienstleistung(en) anbieten.
          </li>
          <li>Akzeptieren Sie die Allgemeinen Geschäftsbedingungen</li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich bin über 18". Nur
            volljährige Personen dürfen die Plattform nutzen.
          </li>
          <li>Abschnitt ADRESSE:</li>
          <li>
            Wählen Sie die Stadt Ihres Wohn- oder Firmensitzes im Feld "Stadt".
          </li>
          <li>
            Wählen Sie Ihre Postleitzahl oder die Ihres Unternehmens im Feld
            "PLZ".
          </li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich akzeptiere die Allgemeinen
            Geschäftsbedingungen".
          </li>
          <br />
          <p>
            Alle anderen Felder sind optional und können jederzeit unter der
            Seite "Mein Konto" aktualisiert werden.
          </p>
          <li>Klicken Sie abschließend auf die Schaltfläche "Registrieren".</li>
          <li>
            Der letzte Schritt besteht darin, Ihre E-Mail-Adresse über den Link
            zu bestätigen, den Sie an die während der Registrierung angegebene
            E-Mail-Adresse erhalten. Wenn Sie die Bestätigungs-E-Mail nicht in
            Ihrem Posteingang finden, überprüfen Sie bitte den Spam-Ordner.
            Nachdem Sie Ihre E-Mail bestätigt haben, sind Sie erfolgreich bei
            servi.co registriert.
          </li>
        </>
      ),
    },
    {
      id: 2,
      question: "Wie komme ich als Kunde?",
      answer: () => (
        <>
          <p>
            Sie können sich einfach und in wenigen Minuten bei servi.co
            anmelden. Um sich als Kund:IN bei servi.co zu registrieren,
            benötigen Sie:
          </p>
          <li>E-Mail-Adresse</li>
          <li>Telefonnummer</li>
          <br />
          <p>
            Beim Erstellen des Kontos bei Servico müssen Sie die folgenden
            Pflichtschritte ausführen:
          </p>
          <li>Abschnitt KONTOINFORMATIONEN</li>
          <li>Wählen Sie "KUND:IN" als Kontotyp.</li>
          <li>Geben Sie einen vollständigen Namen an.</li>
          <li>
            Geben Sie eine E-Mail-Adresse für Ihr Konto im Feld "E-Mail" an.
            Bitte beachten Sie, dass es sich um die E-Mail-Adresse handeln
            sollte, auf die Sie Zugriff haben, da diese für die Kommunikation
            zwischen Ihnen und der Plattform verwendet wird.
          </li>
          <li>
            Wählen Sie ein Passwort für Ihr Konto und geben Sie es im Feld
            "Passwort" ein.
          </li>
          <li>
            Wiederholen Sie dasselbe Passwort im Feld "Passwort wiederholen".
          </li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich bin über 18". Nur
            volljährige Personen dürfen die Plattform nutzen.
          </li>
          <li>Abschnitt ADRESSE</li>
          <li>Wählen Sie die Stadt Ihres Wohnsitzes im Feld "Stadt".</li>
          <li>Wählen Sie die Postleitzahl Ihres Wohnsitzes im Feld "PLZ".</li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich akzeptiere die Allgemeinen
            Geschäftsbedingungen".
          </li>
          <br />
          <p>
            Alle anderen Felder sind optional und können jederzeit unter der
            Seite "Mein Konto" aktualisiert werden.
          </p>
          <li>
            Der letzte Schritt besteht darin, Ihre E-Mail-Adresse über den Link
            zu bestätigen, den Sie an die während der Registrierung angegebene
            E-Mail-Adresse erhalten. Wenn Sie die Bestätigungs-E-Mail nicht in
            Ihrem Posteingang finden, überprüfen Sie bitte den Spam-Ordner.
            Nachdem Sie Ihre E-Mail bestätigt haben, sind Sie erfolgreich bei
            servi.co registriert.
          </li>
        </>
      ),
    },
    {
      id: 3,
      question: "Wie erstelle ich einen neuen Auftrag?",
      answer: () => (
        <>
          <p>
            Um einen neuen Auftrag zu erstellen, navigieren Sie bitte zum
            Abschnitt „Neuer Auftrag“ in der Navigationsleiste. Wenn das
            Formular zur Auftragserstellung geöffnet ist, müssen die folgenden
            Details angegeben werden:
          </p>
          <li>Titel des Auftrags im Feld "Titel".</li>
          <li>Kurze Beschreibung des Auftrags im Feld "Beschreibung".</li>
          <li>Wählen Sie die Kategorie des Auftrags im Feld "Kategorie".</li>
          <li>
            Wählen Sie die Stadt, in der der Auftrag durchgeführt werden soll,
            im Feld "Stadt".
          </li>
          <li>
            Wählen Sie die Postleitzahl, in der der Auftrag durchgeführt werden
            soll, im Feld "PLZ".
          </li>
          <br />
          <p>Alle anderen Felder sind optional.</p>
          <li>
            Wenn der Auftrag fertig ist, klicken Sie auf die Schaltfläche
            „Auftrags-Vorschau“, um die Details Ihres Auftrags anzuzeigen. Wenn
            alles korrekt ist, bestätigen Sie dies, indem Sie auf die
            Schaltfläche „Auftrag erstellen“ klicken. Wenn Sie zur Bearbeitung
            des Auftrags zurückkehren möchten, klicken Sie einfach auf „Auftrag
            bearbeiten“.
          </li>
        </>
      ),
    },
    // {
    //   id: 4,
    //   question: "Wie bewerbe ich mich für einen Auftrag?",
    //   answer: () => (
    //     <p>
    //       Um sich für einen Auftrag zu bewerben, sollte Dienstleister: innen
    //       zunächst in der Navigationsleiste zur Registerkarte „Auftrag
    //       durchsuchen“ navigieren. Auf der Seite „Auftrag durchsuchen“ können
    //       drei Filter (eine Kategorie, eine Stadt und eine Postleitzahl)
    //       ausgewählt werden. Nach Auswahl des Filters bestätigen Sie Ihre
    //       Auswahl mit der Schaltfläche „Suchen“. Anschließend wird Ihnen eine
    //       Liste der Aufgaben angezeigt, die Ihren Filtern entsprechen. Aus
    //       dieser Liste kann Dienstleister: innen die Details jeder Aufgabe
    //       einsehen und mit dem Auftragseigentümer per Nachricht oder
    //       Telefonanruf (sofern verfügbar) Kontakt aufnehmen.
    //     </p>
    //   ),
    // },
    {
      id: 5,
      question: "Wie registriere ich ein Dienstleistungsunternehmen?",
      answer: () => (
        <>
          <p>
            Wenn Sie gerade erst mit Ihrem Unternehmen beginnen, ist es
            möglicherweise sinnvoller, als Kleinunternehmen zu beginnen, da Sie
            dadurch viel Kostenersparnis haben. Wenn Sie vorankommen und mehr
            Umsatz erzielen, ändern Sie auch die Rechtsform Ihres Unternehmens.
            Weitere Details zur Gründung des Kleinunternehmens finden Sie unter
            folgendem Link:
          </p>
          <li>
            Gewerbe anmelden - Voraussetzungen zum Gewerbeschein - Österreich
            (finanz.at)
          </li>
          <li>
            Kleinunternehmerregelung 2023 - Kleinunternehmer - Österreich
            (finanz.at)
          </li>
          <br />
          <p>
            Sie könnten auch jederzeit Ihren kostenlosen Termin bei der
            Wirtschaftskammer vereinbaren, indem Sie eine kostenlose
            Telefonnummer +43 1 514 50 0 anrufen oder via E-Mail info@wkw.at.
            Bei dem Termin können Sie alle Fragen stellen und weitere Details
            zur Unternehmensgründung erfahren.
          </p>
        </>
      ),
    },
    // {
    //   id: 6,
    //   question: "Wie kann ich Dienstleister: innen bewerten?",
    //   answer: () => (
    //     <>
    //       <p>
    //         Als Kunde können Sie für jeden Dienstleister: innen eine Bewertung
    //         abgeben, basierend auf Ihrer Zufriedenheit mit der Zusammenarbeit
    //         hinsichtlich Kommunikation, Servicekompetenz oder Servicepreis. Um
    //         eine Bewertung abzugeben, gibt es zwei Möglichkeiten:
    //       </p>
    //       <li>
    //         Von der Profilseite des Dienstanbieters. Gehen Sie zur Profilseite
    //         des Dienstanbieters. Klicken Sie auf die Schaltfläche „Bewertung
    //         abgeben“ und wählen Sie auf der nächsten Seite die Bewertung aus und
    //         schreiben Sie optional eine Bewertung. Bestätigen Sie Ihre Bewertung
    //         mit der Schaltfläche „Bewertung abgeben“.
    //       </li>
    //       <li>
    //         Aus dem Chat-Bereich. Wenn Sie per Chat mit einem Dienstleister
    //         kommuniziert haben, können Sie direkt im Chat auf die Schaltfläche
    //         „Bewertung abgeben“ klicken und auf der nächsten Seite die Bewertung
    //         auswählen und optional eine Bewertung schreiben. Bestätigen Sie Ihre
    //         Bewertung mit der Schaltfläche „Bewertung abgeben“.
    //       </li>
    //     </>
    //   ),
    // },
    // {
    //   id: 7,
    //   question: "Wie melde ich Benutzer: innen?",
    //   answer: () => (
    //     <p>
    //       Im Falle eines Verstoßes gegen die in den Allgemeinen
    //       Geschäftsbedingungen genannten Kommunikationsregeln können Sie einen
    //       anderen Benutzer melden. Dazu müssen Sie zur Profilseite des Benutzers
    //       navigieren und auf die Schaltfläche „Benutzer melden“ klicken. Auf der
    //       nächsten Seite sollten Sie einige Angaben zum Meldegrund machen, damit
    //       wir die Berechtigung Ihrer Beschwerde überprüfen und die nächsten
    //       Schritte einleiten können. Bestätigen Sie Ihren Anspruch mit der
    //       Schaltfläche „Bericht absenden“.
    //     </p>
    //   ),
    // },
    // {
    //   id: 8,
    //   question: "Wie blockiere ich Benutzer: innen?",
    //   answer: () => (
    //     <p>
    //       Wenn Sie aus irgendeinem Grund verhindern möchten, dass ein anderer
    //       Benutzer Sie kontaktiert, können Sie dies in Ihrer Sperrliste
    //       verwenden. Dazu müssen Sie zur Profilseite dieses Benutzers navigieren
    //       und auf die Schaltfläche „Diesen Benutzer blockieren“ klicken. Von
    //       diesem Moment an kann dieser Benutzer Sie nicht mehr kontaktieren,
    //       wenn Sie ihn oder sie auf Ihrer Sperrliste behalten.
    //     </p>
    //   ),
    // },
    // {
    //   id: 9,
    //   question: "Wie entsperre ich Benutzer:innen?",
    //   answer: () => (
    //     <p>
    //       Sie können einen Benutzer entsperren (aus Ihrer Sperrliste entfernen),
    //       indem Sie zur Profilseite dieses Benutzers navigieren und auf die
    //       Schaltfläche „Diesen Benutzer entsperren“ klicken. Von diesem Moment
    //       an ist dieser Benutzer wieder in der Lage, Sie zu kontaktieren.
    //     </p>
    //   ),
    // },
    // {
    //   id: 10,
    //   question: "Wie speichere ich einen Auftrag in den Favoriten?",
    //   answer: () => (
    //     <p>
    //       Wenn Sie ein Dienstleister sind, können Sie Aufgaben in Ihrer
    //       Favoritenliste speichern und später Kontakt mit ihnen aufnehmen. Dazu
    //       müssen Sie eine Auftragsbeschreibung öffnen und auf ein Sternsymbol
    //       klicken. Um Ihre Favoritenliste anzuzeigen, navigieren Sie zur
    //       Registerkarte „Kommunikation“ in der Navigationsleiste. Unter dem Menü
    //       auf der Seite „Kommunikation“ finden Sie den Abschnitt „Favoriten“ mit
    //       allen von Ihnen gespeicherten Aufgaben.
    //     </p>
    //   ),
    // },
    // {
    //   id: 11,
    //   question: "Wie entferne ich einen Auftrag aus den Favoriten?",
    //   answer: () => (
    //     <p>
    //       Sie können Aufgaben aus der Favoritenliste entfernen, indem Sie in der
    //       Navigationsleiste zur Registerkarte „Kommunikation“ navigieren. Unter
    //       dem Menü auf der Seite „Kommunikation“ finden Sie den Abschnitt
    //       „Favoriten“ mit allen von Ihnen gespeicherten Aufgaben. Um eine
    //       Aufgabe aus der Liste zu entfernen, öffnen Sie die Aufgabe und klicken
    //       Sie auf das Sternsymbol.
    //     </p>
    //   ),
    // },
  ],
  contactUs: {
    email: {
      label: "Email",
      placeholder: "Enter your email",
      error: "Please enter a valid email address",
      name: "email",
      validation: "The entered email address is not valid",
    },
    message: {
      label: "Message",
      placeholder: "Enter your message",
      error: "Please enter a valid message",
      name: "message",
    },
    contact: "Contact us: contact@servi.co",
    button: "Message us",
  },
};

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface HelpItem {
  id: number;
  question: string;
  answer: () => JSX.Element;
  titleClass?: string;
  textClass?: string;
}

interface ContactField {
  label: string;
  placeholder: string;
  error: string;
  name: string;
  validation?: string;
}

interface ContactUs {
  email: ContactField;
  message: ContactField;
  contact: string;
  button: string;
}

interface ErrorFields {
  message: string;
}

export interface IFaqResources {
  title: string;
  modal: Modal;
  help: HelpItem[];
  contactUs: ContactUs;
  errors: ErrorFields;
}
