import img1 from "./bannerfotos/httpsunsplash.comphotosattantive-worker-working-man-plumber-in-bathroom-checking-work-of-wacher-mashine-close-up-B3mvIS6aGVw.jpg";
import img2 from "./bannerfotos/httpsunsplash.comphotoscarpenter-installing-new-laminated-wooden-floor-at-home-ocSbQTyA7-E.jpg";
import img3 from "./bannerfotos/httpsunsplash.comphotoshandyman-worker-assembling-furniture-and-fixing-it-EhSPx8KgLZs.jpg";
import img4 from "./bannerfotos/httpsunsplash.comphotosits-time-for-spring-chores-t5aYodKM4xY.jpg";
import img5 from "./bannerfotos/httpsunsplash.comphotosyoung-plumber-fixing-new-pipe-under-sink-with-wrench-PU9Z6n761bc.jpg";
import img6 from "./bannerfotos/httpsunsplash.comphotosyoung-worker-tiler-installing-ceramic-tiles-using-lever-on-cement-floor-with-heating-red-electrical-cable-wire-system-home-impro.jpg";

export const CarouselTextEN: CarouselItem[] = [
  {
    text: "Free and no-obligation membership until 28.02.2025.",
    image: img1,
    alt: "Download this HD photo of plumber, electrical, plumbing and hvac, plumbing services, and tradesman by Getty Images (@gettyimages)",
  },
  {
    text: "Promotion valid only until 31.12.2024.",
    image: img2,
    alt: "Download this HD photo of hardwood, diy, installing, and flooring by Getty Images (@gettyimages)",
  },
  {
    text: "Bring your business to another level. Join now!",
    image: img3,
    alt: "Download this HD photo of craftsperson, men, repairing, and service by Getty Images (@gettyimages)",
  },
  {
    text: "Free and no-obligation membership until 28.02.2025.",
    image: img4,
    alt: "Download this HD photo of cleaner, housework, cleaning services, and cleaning service by Getty Images (@gettyimages)",
  },
  {
    text: "Promotion valid only until 31.12.2024.",
    image: img5,
    alt: "Download this HD photo of plumber, tradesman, plumbers, and trades by Getty Images (@gettyimages)",
  },
  {
    text: "Bring your business to another level. Join now!",
    image: img6,
    alt: "https://unsplash.com/photos/young-worker-tiler-installing-ceramic-tiles-using-lever-on-cement-floor-with-heating-red-electrical-cable-wire-system-home-impro",
  },
];

export const CarouselTextDE: CarouselItem[] = [
  {
    text: "Kostenlose und unverbindliche Mitgliedschaft bis 28.02.2025.",
    image: img1,
    alt: "Download this HD photo of plumber, electrical, plumbing and hvac, plumbing services, and tradesman by Getty Images (@gettyimages)",
  },
  {
    text: "Die Aktion gilt nur bis zum 31.12.2024.",
    image: img2,
    alt: "Download this HD photo of hardwood, diy, installing, and flooring by Getty Images (@gettyimages)",
  },
  {
    text: "Bringen Sie Ihr Geschäft auf ein anderes Niveau. Treten Sie jetzt bei!",
    image: img3,
    alt: "Download this HD photo of craftsperson, men, repairing, and service by Getty Images (@gettyimages)",
  },
  {
    text: "Kostenlose und unverbindliche Mitgliedschaft bis 28.02.2025.",
    image: img4,
    alt: "Download this HD photo of cleaner, housework, cleaning services, and cleaning service by Getty Images (@gettyimages)",
  },
  {
    text: "Die Aktion gilt nur bis zum 31.12.2024.",
    image: img5,
    alt: "Download this HD photo of plumber, tradesman, plumbers, and trades by Getty Images (@gettyimages)",
  },
  {
    text: "Bringen Sie Ihr Geschäft auf ein anderes Niveau. Treten Sie jetzt bei!",
    image: img6,
    alt: "https://unsplash.com/photos/young-worker-tiler-installing-ceramic-tiles-using-lever-on-cement-floor-with-heating-red-electrical-cable-wire-system-home-impro",
  },
];

export interface CarouselItem {
  text: string;
  image: string; // or you can use the appropriate type, e.g., `Image` if it's an image object
  alt: string;
}
