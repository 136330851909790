import { EnumSubscriptionType } from "../../redux/interfaces";

export const PricingResourcesEN: IPricingResources = {
  title: "PLANS AND PRICES",
  subtitle:
    "Choose your plan and boost your business.",
  plans: [
    {
      id: 1,
      name: "TASK HUNT",
      price: 49,
      duration: "license",
      description: () => (
        <p>
          Browse tasks unlimited and receive notifications for each new task in your categories.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BASIC1,
          feature: () => (
            <>
              1 month <b>EUR 59,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC3,
          feature: () => (
            <>
              3 months (-5%) <b>EUR 56,99 per month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC6,
          feature: () => (
            <>
              6 months (-15%) <b>EUR 50,99 per month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC12,
          feature: () => (
            <>
              12 months (-30%) <b>EUR 41,99 per month</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 2,
      name: "PHONEBOOK POWER",
      price: 149,
      duration: "license",
      description: () => (
        <p>
          Tragen Sie sich in das Servi.co-Telefonbuch ein und erhöhen Sie Ihre Sichtbarkeit. 
          Manche Kunden ziehen es vor, Dienstanbieter direkt zu kontaktieren.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.PHONEBOOK1,
          feature: () => (
            <>
              1 month <b>EUR 29,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK3,
          feature: () => (
            <>
              3 months (-5%) <b>EUR 28,49 per month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK6,
          feature: () => (
            <>
              6 months (-15%) <b>EUR 25,49 per month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK12,
          feature: () => (
            <>
              12 months (-30%) <b>EUR 20,99 per month</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 3,
      name: "BUNDLE",
      price: 999,
      duration: "license",
      description: () => (
        <p>
          Use all the benefits of TASK HUNT and
          PHONEBOOK POWER combined, at the discounted price.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BUNDLE1,
          feature: () => (
            <>
              1 month <b>EUR 79,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE3,
          feature: () => (
            <>
              3 months (-10%) <b>EUR 71,99 per month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE6,
          feature: () => (
            <>
              6 months (-20%) <b>EUR 63,99 per month</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE12,
          feature: () => (
            <>
              12 months (-30%) <b>EUR 55,99 per month</b>
            </>
          ),
        },
      ],
      isRecommended: true,
    },
  ],
};

export const PricingResourcesDE: IPricingResources = {
  title: "PLÄNE UND PREISE",
  subtitle:
    "Wählen Sie Ihren Plan und ankurbeln Sie Ihr Geschäft.",
  plans: [
    {
      id: 1,
      name: "TASK HUNT",
      price: 49,
      duration: "license",
      description: () => (
        <p>
          Durchsuchen Sie Aufgaben unbegrenzt und erhalten Sie Benachrichtigungen 
          für jede neue Aufgabe in Ihren Kategorien.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BASIC1,
          feature: () => (
            <>
              1 Monat <b>EUR 59,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC3,
          feature: () => (
            <>
              3 Monate (-5%) <b>EUR 56,99 pro Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC6,
          feature: () => (
            <>
              6 Monate (-15%) <b>EUR 50,99 pro Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BASIC12,
          feature: () => (
            <>
              12 Monate (-30%) <b>EUR 41,99 pro Monat</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 2,
      name: "PHONEBOOK POWER",
      price: 149,
      duration: "license",
      description: () => (
        <p>
          Get entered into Servi.co Phonebook and increase your visibility
          during the chosen period. Some clients like to browse service
          providers and contact directly without job post.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.PHONEBOOK1,
          feature: () => (
            <>
              1 Monat <b>EUR 29,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK3,
          feature: () => (
            <>
              3 Monate (-5%) <b>EUR 28,49 pro Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK6,
          feature: () => (
            <>
              6 Monate (-15%) <b>25,49 EUR pro Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.PHONEBOOK12,
          feature: () => (
            <>
              12 Monate (-30%) <b>20,99 EUR pro Monat</b>
            </>
          ),
        },
      ],
      isRecommended: false,
    },
    {
      id: 3,
      name: "BUNDLE",
      price: 999,
      duration: "license",
      description: () => (
        <p>
          Nutzen Sie alle Vorteile von TASK HUNT und PHONEBOOK POWER kombiniert zum reduzierten Preis.
        </p>
      ),
      features: [
        {
          type: EnumSubscriptionType.BUNDLE1,
          feature: () => (
            <>
              1 Monat (-5%) <b>EUR 85,99</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE3,
          feature: () => (
            <>
              3 Monate (-15%) <b>76,49 EUR pro Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE6,
          feature: () => (
            <>
              6 Monate (-20%) <b>EUR 71,99 pro Monat</b>
            </>
          ),
        },
        {
          type: EnumSubscriptionType.BUNDLE12,
          feature: () => (
            <>
              12 Monate (-30%) <b>EUR 62,99 pro Monat</b>
            </>
          ),
        },
      ],
      isRecommended: true,
    },
  ],
};
export interface IPricingResources {
  title: string;
  subtitle: string;
  plans: PlanItemsTypes[];
}

interface IFeatureItem {
  feature: () => React.ReactNode;
  type: EnumSubscriptionType;
}

export interface PlanItemsTypes {
  id: number;
  name: string;
  price: number;
  duration: string;
  features: IFeatureItem[];
  isRecommended: boolean;
  description: () => React.ReactNode;
}
