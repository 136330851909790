export const FooterResourcesEN: IFooterResources = {
  aboutUs: {
    title: "ABOUT US",
    what: "WHAT IS SERVICO?",
    blog: "BLOG",
    press: "PRESS",
  },
  information: {
    title: "TITLE",
    contactUs: "CONTACT US",
    termsOfUse: "TERMS OF USE",
    myData: "MY DATA",
    dataProtection: "DATA PROTECTION",
  },
  forBussiness: {
    title: "FOR BUSINESSES",
    partnership: "PARTNERSHIP",
  },
  marketing: {
    title: "MARKETING",
  },
  notifications: {
    clearall: "Clear all",
    notfound: "No new notifications",
    title: "Notification",
    noNotifications: "You have no notifications.",
    notificationTypes: {
      message: "You recieved a new message!",
      postOne: "Your post is going to be archived tomorrow.",
      postTwo: "Your post is going to be archived in two days.",
      review: "You recieved a new review!",
      unknown:
        "Hm... Some weird notification. Please contact the administrator.",
    },
  },
  maindropdown: {
    logout: "Logout",
    myaccount: "My Account",
    notfound: "Not logged in",
    welcome: "Welcome",
    login: "Login",
  },
  languages: {
    de: "German",
    en: "English",
  },
};

export const FooterResourcesDE: IFooterResources = {
  aboutUs: {
    title: "ÜBER UNS",
    what: "WAS IST SERVICO?",
    blog: "BLOG",
    press: "PRESS",
  },
  information: {
    title: "TITEL",
    contactUs: "KONTAKTIERE UNS",
    termsOfUse: "NUTZUNGSBEDINGUNGEN",
    myData: "MEINE DATEN",
    dataProtection: "DATENSCHUTZ",
  },
  forBussiness: {
    title: "FÜR UNTERNEHMEN",
    partnership: "PARTNERSCHAFT",
  },
  marketing: {
    title: "MARKETING",
  },
  notifications: {
    clearall: "Alle Löschen",
    notfound: "Keine neuen Benachrichtigungen",
    title: "Benachrichtigungen",
    noNotifications: "Sie haben keine neuen Benachrichtigungen.",
    notificationTypes: {
      message: "Sie haben eine neue Nachricht!",
      postOne: "Ihr Beitrag wird morgen archiviert.",
      postTwo: "Ihr Beitrag wird in zwei Tagen archiviert.",
      review: "Du hast eine neue Bewertung erhalten!",
      unknown:
        "Hm... Irgendeine merkwürdige Benachrichtigung. Bitte kontaktieren Sie den Administrator.",
    },
  },
  maindropdown: {
    logout: "Abmelden",
    myaccount: "Mein Konto",
    notfound: "Nicht eingeloggt",
    welcome: "Willkommen",
    login: "Anmelden",
  },
  languages: {
    de: "Deutsch",
    en: "Englisch",
  },
};

interface AboutUs {
  title: string;
  what: string;
  blog: string;
  press: string;
}

interface Information {
  title: string;
  contactUs: string;
  termsOfUse: string;
  myData: string;
  dataProtection: string;
}

interface ForBusiness {
  title: string;
  partnership: string;
}

interface Marketing {
  title: string;
}

interface Notifications {
  title: string;
  notfound: string;
  clearall: string;
  notificationTypes: {
    message: string;
    review: string;
    postOne: string;
    postTwo: string;
    unknown: string;
  };
  noNotifications: string;
}

interface MainDropdown {
  notfound: string;
  myaccount: string;
  logout: string;
  welcome: string;
  login: string;
}

interface Languages {
  en: string;
  de: string;
}

export interface IFooterResources {
  aboutUs: AboutUs;
  information: Information;
  forBussiness: ForBusiness; // Note the typo 'Bussiness' to match your original object; consider correcting it to 'Business' if that was unintentional.
  marketing: Marketing;
  notifications: Notifications;
  maindropdown: MainDropdown;
  languages: Languages;
}
