export const HomeResourcesEN: IHomeResources = { 
  title: "Einfach! Schnell! Service!",
  messageBtn: "Open offer",
};

export const HomeResourcesDE: IHomeResources = {
  title: "Einfach! Schnell! Service!", 
  messageBtn: "Angebot öffnen",
};
 

export interface IHomeResources {
  title: string;
  messageBtn: string;
}
