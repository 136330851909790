import React from "react";

export const authEN: IAuth = {
  title: "Create Account",
  aboutMeDescription: () => <React.Fragment>Some Element</React.Fragment>,
  registerButton: "Register",
  errors: {
    login: 
      "Your account was not found! Please check your email or password.",
    register: "Account creation failed. Either your E-mail was already registered or there was an other issue. Please contact the customer support.",
    registerPic: "The picture you are trying to upload is too large.",
    emailNotFound: "Your account was not found.",
    emailFound: "Please check your E-mail and reset your password.", 
    errorPsw:
      "An error occured while updating your password. Please contact the customer support.",
  },
  activate: {
    title: "Please activate your account.",
    success:
      "Your E-Mail has been successfully confirmed. Thank you and welcome to Servi.co!",
    failure:
      "Unfortunately, your account could not be activated. Make sure you confirmed your E-mail. You can request another confirmation E-mail here. If you need help, please contact the customer support.",
  },
  forgetPassword: {
    modal: {
      title: "Success Modal",
      text: "Your account has been successfully updated!",
      button: "Done",
    },
    checkTempButton: "Get new password.",
    email: {
      label: "E-mail",
      name: "email",
      error: "Please enter a valid E-mail.",
      validation: "Wrong E-mail",
      placeholder: "Enter a valid E-mail",
    },
    tempPassword: {
      label: "One-time password",
      name: "tempPassword",
      error: "Please enter your one-time password.",
      placeholder: "Enter your one-time password",
    },
    password: {
      label: "Password",
      name: "password",
      error: "Please enter a valid password.",
      validation: "Wrong password",
    },
    passwordRepeat: {
      label: "Repeat password",
      name: "repeatPassword",
      error: "Please repeat your password.",
      validation: "Wrong password",
      matchError: "The password doesn't match.",
    },
    title:
      "Please enter your E-mail to receive the password reset instructions.",
    changeButton: "Reset password",
    findButton: "Find User",
    login: {
      description: "Remembered your password?",
      button: "Sign In!",
    },
    register: {
      description: "Don't have an account?",
      button: "Register now!",
    },
  },
  confirm: {
    title: "Success!",
    button: "Back to Login",
    description:
      "An email has been send to you. Please check your inbox and click on the link in the E-mail to confirm your account.",
  },
  login: {
    title: "Please enter your credentials.",
    email: "Email",
    password: "Password",
    button: "Login",
    pswForgotten: "Password forgotten.",
    noAccount: "Don’t have an account? Sign Up!",
    emailError: "Invalid Email address",
    emailRequired: "Please enter your E-mail.",
    passwordRequired: "Please enter your password.",
    signup: "Sign Up",
  },
  register: {
    title: "Some Title",
    titles: {
      account: "Accound details",
      address: "Address",
      personal: "Personal",
    },
    registerDescription:
      "Don't have an account? Create one, it takes less than a minute!",
    registerButton: "Sign Up",
    login: {
      title: "Already have account?",
      button: "Sign In",
    },
    fields: {
      description: {
        label: "About you",
        placeholder: "Please introduce yourself",
        name: "description",
        error: "Please introduce yourself.",
      },
      termsConditions: {
        label: "I accept Terms and Conditions.",
        name: "termsConditions",
        error: "Please accept the general Terms and Conditions.",
        validation: "General Terms and Conditions not accepted.",
      },
      oldEnough: {
        label: "I’m over 18",
        name: "oldEnough",
        error: "Plase check the 18+ box",
        validation: "The 18+ box not selected.",
      },
      type: {
        label: "Account type",
        name: "type",
        error: "Please select your account type.",
        validation: "Wrong account type.",
        client: "CLIENT",
        supplier: "SERVICE PROVIDER",
        tooltip:
          "If you’re looking for a service, please choose CLIENT. If  you’re offering a service, please choose SERVICE PROVIDER.",
      },
      email: {
        label: "Email",
        name: "email",
        error: "Please enter a valid E-mail.",
        validation: "Wrong E-mail.",
      },
      password: {
        label: "Password",
        name: "password",
        error: "Please enter a valid password.",
        validation: "Wrong password.",
      },
      repeatPassword: {
        label: "Repeat password",
        name: "repeatPassword",
        error: "Please repeat your password.",
        validation: "Wrong password.",
        matchError: "Your password doesn't match.",
      },
      name: {
        label: "First and last name",
        name: "name",
        error: "Please enter a valid name.",
        validation: "Wrong name",
      },
      address: { 
        postalCode: {
          label: "ZIP code",
          name: "postalCode",
          error: "Please enter a valid ZIP code.",
          validation: "Wrong ZIP code.", 
              zeroOption: "Please select your city first.",
          placeholder: "Please select your postal code.", 
        },
        country: {
          label: "Country",
          name: "country",
          error: "Please select a country.",
          validation: "Wrong country.",
          countries: {
            AUSTRIA: "Austria",
          },
        },
        cityId: {
          label: "City", 
          name: "cityId",
          error: "Please select a city.",
          validation: "Wrong city.", 
          cities: {
            AUSTRIA: {
              VIENNA: "Vienna",
            },
          },
        },
        street: {
          label: "Street",
          name: "street",
          error: "Please enter a valid street.",
          validation: "Wrong street.",
        },
        elevator: {
          label: "Elevator",
          name: "elevator",
          error: "Please select an elevator option.",
          validation: "Wrong entry.",
        },
        stairs: {
          label: "Entrance",
          name: "entrance",
          error: "Please enter a valid entrance.",
          validation: "Wrong entry.",
        },
        floor: {
          label: "Floor",
          name: "floor",
          error: "Please enter a valid floor number.",
          validation: "Wrong entry.",
        },
        apartmentNumber: {
          label: "Apartment number",
          name: "apartmentnumber",
          error: "Please enter a valid apartment number.",
          validation: "Wrong entry.",
        },
      },
      phoneNumber: {
        label: "Phone number ",
        placeholder: "Expected input: +436...",
        name: "phoneNumber",
        error: "Please enter a valid phone number (For example: +436...)",
        validation: "Wrong entry",
      },
      profilePicture: {
        label: "Profile picture",
        name: "profilePicture",
        error: "Please select your profile picture.",
        validation: "Wrong entry",
        description: () => (
          <>
            <h4> files here or click to upload.</h4>
            <span className="text-muted font-13">Only images, max 4MB</span>
          </>
        ),
      },
      birthDate: {
        label: "Birth date",
        name: "birthDate",
        error: "Please enter a valid birth date.",
        validation: "Wrong entry.",
      },
      serviceCompany: {
        label: "Are you a company?",
        name: "serviceCompany",
        error: "Please enter a valid company selection.",
        validation: "",
      },
      serviceCompanyName: {
        label: "Company name",
        name: "serviceCompanyName",
        error: "Please enter a valid company name.",
        validation: "Wrong selection.",
      },
      categories: {
        label: "Categories",
        tooltip: "Please select the services, that you offer.",
        name: "categories",
        error: "Please select your categories.",
        validation: "Wrong selection.",
      },
      ortSelektiert: {
        label: "ZIP selection",
        name: "ortSelektiert",
        tooltip:
          "Please select where do you want to provide your services.",
        error: "Please select the valid ZIP codes",
        validation: "Wrong selection.",
        options: [
          {
            label: "1010",
            value: "1010",
          },
          {
            label: "1020",
            value: "1020",
          },
          {
            label: "1030",
            value: "1030",
          },
          {
            label: "1040",
            value: "1040",
          },

          {
            label: "1050",
            value: "1050",
          },
          {
            label: "1060",
            value: "1060",
          },
          {
            label: "1070",
            value: "1070",
          },
        ],
      },
    },
  },
  logout: {
    title: "See you again!",
    subtitle: "You are successfully sign out.",
    button: "Sign In",
  },
};

export const authDE: IAuth = {
  title: "Some Title",
  aboutMeDescription: () => <React.Fragment>Some Element</React.Fragment>,
  registerButton: "Register",
  errors: {
    login:
      "Ihr Konto wurde nicht gefunden. Bitte prüfen Sie Ihre E-Mail oder Ihr Passwort.",
    register:
      "Die Kontoerstellung ist fehlgeschlagen. Entweder war Ihre E-Mail bereits registriert oder es gab ein anderes Problem. Bitte wenden Sie sich an den Kundensupport.",
    registerPic: "Das Foto dass sie hochladen wollen ist zu groß.",
    emailNotFound: "Ihr Account wurde nicht gefunden.",
    emailFound: "Bitte überprüfen Sie Ihre E-Mail und setzen Sie Ihr Passwort zurück.",
    errorPsw:
      "Beim Aktualisieren Ihres Passworts ist ein Fehler aufgetreten. Wenden Sie sich bitte an den Kundendienst.",
  },
  activate: {
    title: "Aktivieren Sie Ihren Account!",
    success: "Ihre E-Mail wurde erfolgreich bestätigt. Vielen Dank und willkommen bei Servi.co!",
    failure:
      "Leider konnte Ihr Account nicht aktiviert werden. Bitte bestätigen Sie Ihre E-Mail. Sie können hier eine weitere Bestätigungsmail anfordern. Sollten Sie Hilfe benötigen, wenden Sie sich bitte an den Kundensupport.",
  },
  forgetPassword: {
    modal: {
      title: "Success Modal",
      text: "Ihr Konto wurde erfolgreich aktualisiert!",
      button: "Erledigt.",
    },
    checkTempButton: "Neues Passwort Erstellen",
    email: {
      label: "E-mail",
      name: "email",
      error: "Bitte geben Sie eine gültige Email-Adresse ein.",
      validation: "Falsche E-mail",
      placeholder: "Geben Sie eine gültige Email-Adresse ein.",
    },
    tempPassword: {
      label: "Einmaliges Password",
      name: "tempPassword",
      error: "Bitte geben Sie Ihr einmaligen Passoword ein.",
      placeholder: "Geben Sie Ihr einmaligen Passoword ein.",
    },
    password: {
      label: "Passwort",
      name: "password",
      error: "Bitte geben Sie ein gültiges Passwort ein.",
      validation: "Falsches Passwort.",
    },
    passwordRepeat: {
      label: "Passwort wiederholen",
      name: "repeatPassword",
      error: "Bitte wiederholen Sie Ihr Passwort.",
      validation: "Falsches Passwort",
      matchError: "Ihr Passwort stimmt nicht überein.",
    },
    title:
      "Bitte geben Sie Ihre E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen des Passworts zu erhalten.",
    login: {
      description: "Haben Sie Ihr Passwort in Erinnerung?",
      button: "Anmelden!",
    },
    register: {
      description: "Haben Sie kein Konto?",
      button: "Jetzt registrieren!",
    },
    changeButton: "Passwort Ändern",
    findButton: "Benutzer Finden",
  },
  confirm: {
    title: "Erfolg!",
    button: "Zurück zum Login",
    description:
      "Ihnen wurde eine E-Mail zugesandt. Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf den Link in der E-Mail, um Ihr Konto zu bestätigen.",
  },
  login: {
    title: "Bitte geben Sie Ihre Zugangsdaten ein.",
    email: "Email",
    password: "Passwort",
    button: "Einloggen",
    pswForgotten: "Passwort vergessen",
    noAccount: "Haben Sie noch kein Konto? Melden Sie sich an!",
    emailError: "Ungültige E-Mail-Adresse.",
    emailRequired: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
    passwordRequired: "Bitte geben Sie Ihr Passwort ein.",
    signup: "Registrieren",
  },
  register: {
    title: "Some Title",
    titles: {
      account: "Accound Details",
      address: "Addresse",
      personal: "Perönliches",
    },
    registerDescription:
      "Sie haben noch kein Konto? Erstellen Sie eins, es dauert weniger als eine Minute.",
    registerButton: "Registrieren",
    login: {
      title: "Sie haben bereits ein Konto?",
      button: "Anmelden",
    },
    fields: {
      description: {
        label: "Über Sie",
        placeholder: "Stellen Sie sich bitte vor.",
        name: "description",
        error: "Stellen Sie sich bitte vor.",
      },
      termsConditions: {
        label: "Ich akzeptiere die Allgemeinen Geschäftsbedingungen",
        name: "termsConditions",
        error: "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen.",
        validation: "Not selected",
      },
      oldEnough: {
        label: "Ich bin über 18",
        name: "oldEnough",
        error: "Das Feld '18+' nicht selektiert.",
        validation: "Not selected",
      },
      type: {
        label: "Konto Typ",
        name: "type",
        error: "Bitte wählen Sie den Kontotyp aus.",
        validation: "Falscher Kontotyp",
        client: "KUND:INNEN",
        supplier: "DIENSTLEISTER:IN",
        tooltip:
          "Wenn Sie eine Dienstleistung suchen, wählen Sie bitte KUND:INNEN. Wenn Sie eine Dienstleistung anbieten, wählen Sie bitte DIENSTLEISTER:IN.",
      },
      email: {
        label: "Email",
        name: "email",
        error: "Bitte geben Sie eine gültige Email-Adresse ein.",
        validation: "Falsche E-mail.",
      },
      password: {
        label: "Passwort",
        name: "password",
        error: "Bitte geben Sie ein gültiges Passwort ein.",
        validation: "Falsches Passwort.",
      },
      repeatPassword: {
        label: "Passwort wiederholen",
        name: "repeatPassword",
        error: "Bitte wiederholen Sie Ihr Passwort.",
        validation: "Falsches Passwort.",
        matchError: "Ihr Passwort stimmt nicht überein.",
      },
      name: {
        label: "Vor- und Nachname",
        name: "name",
        error: "Bitte geben Sie einen gültigen Namen ein.",
        validation: "Falscher Name.",
      },
      address: {
        postalCode: {
          label: "PLZ", 
          name: "postalCode",
          error: "Bitte geben Sie eine gültige PLZ ein.",
          validation: "Falsche PLZ.",  
          zeroOption: "Bitte wählen Sie erst die Stadt aus.",
          placeholder: "Bitte wählen Sie Ihre Postleitzahl aus.",
        },
        country: {
          label: "Land",
          name: "country",
          error: "Bitte wählen Sie ein Land aus.",
          validation: "Falsches Land.",
          countries: {
            AUSTRIA: "Österreich",
          },
        },
        cityId: {
          label: "Stadt", 
          name: "cityId",
          error: "Bitte wählen Sie eine Stadt aus.",
          validation: "Falsche Stadt.", 
          cities: {
            AUSTRIA: {
              VIENNA: "Wien",
            },
          },
        },
        street: {
          label: "Straße",
          name: "street",
          error: "Bitte geben Sie eine gültige Straße ein.",
          validation: "Falsche Straße.",
        },
        elevator: {
          label: "Aufzug",
          name: "elevator",
          error: "Bitte wählen Sie eine Aufzugsoption aus.",
          validation: "Falsche Auswahl.",
        },
        stairs: {
          label: "Stiege",
          name: "stairs",
          error: "Bitte geben Sie gültige Stiege ein.",
          validation: "Falscher Eintrag.",
        },
        floor: {
          label: "Stock",
          name: "floor",
          error: "Bitte geben Sie einen gültigen Stock ein",
          validation: "Falscher Eintrag",
        },
        apartmentNumber: {
          label: "Türnummer",
          name: "apartmentnumber",
          error: "Bitte geben Sie eine gültige Türnummer ein.",
          validation: "Falscher Eintrag.",
        },
      },
      phoneNumber: {
        label: "Telefonnummer",
        placeholder: "Erwartete Eingabe: +436...",
        name: "phoneNumber",
        error:
          "Bitte geben Sie eine gültige Telefonnummer ein (Zum Beispiel: +436...).",
        validation: "Falsche Telefonnummer.",
      },
      profilePicture: {
        label: "Profilbild",
        name: "profilePicture",
        error: "Bitte wählen Sie Ihr Profilbild aus.",
        validation: "Falscher Eintrag.",
        description: () => (
          <>
            <h4> files here or click to upload.</h4>
            <span className="text-muted font-13">Only images, max 4MB</span>
          </>
        ),
      },
      birthDate: {
        label: "Geburtsdatum",
        name: "birthDate",
        error: "Bitte geben Sie ein gültiges Geburtsdatum ein.",
        validation: "Falscher Eintrag.",
      },
      serviceCompany: {
        label: "Sind Sie eine Firma?",
        name: "serviceCompany",
        error: "Bitte kreuzen Sie das Kontrollkästchen für Unternehmen an.",
        validation: "",
      },
      serviceCompanyName: {
        label: "Firmenname",
        name: "serviceCompanyName",
        error: "Bitte geben Sie einen gültigen Firmennamen ein.",
        validation: "Falscher Firmenname.",
      },
      categories: {
        label: "Kategorien",
        tooltip: "Bitte wählen Sie die Leistungen aus, die Sie anbieten.",
        name: "categories",
        error: "Bitte wählen Sie Ihre Leistungen aus.",
        validation: "Falscher Eintrag",
      },
      ortSelektiert: {
        label: "Ort Selektiert",
        name: "ortSelektiert",
        tooltip:
          "Bitte wählen Sie aus, wo Sie Ihre Dienste anbieten möchten.",
        error: "Bitte wählen Sie die PLZ aus.",
        validation: "Falscher Eintrag",
        options: [
          { label: "1010", value: "1010" },
          { label: "1020", value: "1020" },
          { label: "1030", value: "1030" },
          { label: "1040", value: "1040" },
          { label: "1050", value: "1050" },
          { label: "1060", value: "1060" },
          { label: "1070", value: "1070" },
          { label: "1080", value: "1080" },
          { label: "1090", value: "1090" },
          { label: "1100", value: "1100" },
          { label: "1110", value: "1110" },
          { label: "1120", value: "1120" },
          { label: "1130", value: "1130" },
          { label: "1140", value: "1140" },
          { label: "1150", value: "1150" },
          { label: "1160", value: "1160" },
          { label: "1170", value: "1170" },
          { label: "1180", value: "1180" },
          { label: "1190", value: "1190" },
          { label: "1200", value: "1200" },
          { label: "1210", value: "1210" },
          { label: "1220", value: "1220" },
          { label: "1230", value: "1230" },
        ],
      },
    },
  },
  logout: {
    title: "Bis bald!",
    subtitle: "Sie wurden erfolgreich abgemeldet.",
    button: "Anmelden",
  },
};

interface AuthDescriptionProps {
  children?: React.ReactNode;
}

interface FieldValidation {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
  matchError?: string;
}

interface FieldValidationpostalCode {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
  zeroOption?: string;
}

interface AddressField {
  postalCode: FieldValidationpostalCode;
  country: FieldValidation & { countries: Record<string, string> };
  cityId: FieldValidation & { cities: Record<string, Record<string, string>> };
  street: FieldValidation;
  elevator: FieldValidation;
  stairs: FieldValidation;
  floor: FieldValidation;
  apartmentNumber: FieldValidation;
}

interface AccountTypeField extends FieldValidation {
  client: string;
  supplier: string;
  tooltip: string;
}

interface CategoriesField extends FieldValidation {
  tooltip: string;
}

interface OrtSelektiertField extends FieldValidation {
  tooltip: string;
  options: { label: string; value: string }[];
}

interface ProfilePictureField extends FieldValidation {
  description: () => void;
}

interface LoginSection {
  title: string;
  email: string;
  password: string;
  button: string;
  pswForgotten: string;
  noAccount: string;
  emailError: string;
  emailRequired: string;
  passwordRequired: string;
  signup: string;
}

interface RegisterSection {
  title: string;
  registerDescription: string;
  registerButton: string;
  titles: {
    account: string;
    address: string;
    personal: string;
  };
  login: {
    title: string;
    button: string;
  };
  fields: {
    description: FieldValidation;
    termsConditions: FieldValidation;
    oldEnough: FieldValidation;
    type: AccountTypeField;
    email: FieldValidation;
    password: FieldValidation;
    repeatPassword: FieldValidation;
    name: FieldValidation;
    address: AddressField;
    phoneNumber: FieldValidation;
    profilePicture: ProfilePictureField;
    birthDate: FieldValidation;
    serviceCompany: FieldValidation;
    serviceCompanyName: FieldValidation;
    categories: CategoriesField;
    ortSelektiert: OrtSelektiertField;
  };
}

interface ErrorFields {
  login: string;
  register: string;
  registerPic: string;
  emailNotFound: string;
  emailFound: string;
  errorPsw: string;
}

interface LogoutSection {
  title: string;
  subtitle: string;
  button: string;
}

interface ConfirmSection {
  title: string;
  description: string;
  button: string;
}

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface ForgetPassword {
  title: string;
  email: FieldValidation;
  password: FieldValidation;
  passwordRepeat: FieldValidation;
  tempPassword: FieldValidation;
  findButton: string;
  checkTempButton: string;
  changeButton: string;
  login: {
    description: string;
    button: string;
  };
  register: {
    description: string;
    button: string;
  };
  modal: Modal;
}

interface ActivateSection {
  title: string;
  success: string;
  failure: string;
}

export interface IAuth {
  title: string;
  aboutMeDescription: React.ComponentType<AuthDescriptionProps>;
  registerButton: string;
  login: LoginSection;
  register: RegisterSection;
  logout: LogoutSection;
  confirm: ConfirmSection;
  errors: ErrorFields;
  forgetPassword: ForgetPassword;
  activate: ActivateSection;
}

// interface AddressData {
//   [region: string]: RegionData;
// }

// interface RegionData {
//   cities: {
//     [cityId: string]: CityData;
//   };
//   postal_codes: {
//     [cityId: string]: string[];
//   };
// }

// interface CityData {
//   Bezirke: string[];
// }

export type AddressData = {
  [state: string]: {
    cities: {
      [city: string]: {
        Bezirke: string[];
      };
    };
    postal_codes: {
      [city: string]: string[];
    };
  };
};

export const addressData: AddressData = {
  Burgenland: {
    cities: {
      Eisenstadt: {
        Bezirke: ["Eisenstadt"],
      },
      Rust: {
        Bezirke: ["Eisenstadt-Umgebung"],
      },
    },
    postal_codes: {
      Eisenstadt: ["7000"],
      Rust: ["7071"],
    },
  },
  Kärnten: {
    cities: {
      "Feldkirchen in Kärnten": {
        Bezirke: ["Feldkirchen"],
      },
      Hermagor: {
        Bezirke: ["Hermagor"],
      },
      "Klagenfurt am Wörthersee": {
        Bezirke: ["Klagenfurt-Land", "Klagenfurt-Stadt"],
      },
      "Spittal an der Drau": {
        Bezirke: ["Spittal an der Drau"],
      },
      "St. Veit an der Glan": {
        Bezirke: ["Sankt Veit an der Glan"],
      },
      Villach: {
        Bezirke: ["Villach-Land", "Villach-Stadt"],
      },
      Völkermarkt: {
        Bezirke: ["Völkermarkt"],
      },
      Wolfsberg: {
        Bezirke: ["Wolfsberg"],
      },
    },
    postal_codes: {
      "Feldkirchen in Kärnten": ["9560"],
      Hermagor: ["9620"],
      "Klagenfurt am Wörthersee": ["9020", "9063"],
      "Spittal an der Drau": ["9800"],
      "St. Veit an der Glan": ["9300"],
      Villach: ["9500", "9580"],
      Völkermarkt: ["9100"],
      Wolfsberg: ["9400"],
    },
  },
  Niederösterreich: {
    cities: {
      Amstetten: {
        Bezirke: ["Amstetten"],
      },
      Baden: {
        Bezirke: ["Baden"],
      },
      "Bruck an der Leitha": {
        Bezirke: ["Bruck an der Leitha"],
      },
      Gänserndorf: {
        Bezirke: ["Gänserndorf"],
      },
      Gmünd: {
        Bezirke: ["Gmünd"],
      },
      Hollabrunn: {
        Bezirke: ["Hollabrunn"],
      },
      Horn: {
        Bezirke: ["Horn"],
      },
      Korneuburg: {
        Bezirke: ["Korneuburg"],
      },
      "Krems an der Donau": {
        Bezirke: ["Krems an der Donau"],
      },
      Lilienfeld: {
        Bezirke: ["Lilienfeld"],
      },
      Melk: {
        Bezirke: ["Melk"],
      },
      Mistelbach: {
        Bezirke: ["Mistelbach"],
      },
      Mödling: {
        Bezirke: ["Mödling"],
      },
      Neunkirchen: {
        Bezirke: ["Neunkirchen"],
      },
      "Sankt Pölten": {
        Bezirke: ["Sankt Pölten", "Sankt Pölten-Land"],
      },
      Scheibbs: {
        Bezirke: ["Scheibbs"],
      },
      Tulln: {
        Bezirke: ["Tulln"],
      },
      "Waidhofen an der Thaya": {
        Bezirke: ["Waidhofen an der Thaya"],
      },
      "Wiener Neustadt": {
        Bezirke: ["Wiener Neustadt", "Wiener Neustadt-Land"],
      },
      Zwettl: {
        Bezirke: ["Zwettl"],
      },
    },
    postal_codes: {
      Amstetten: ["3300"],
      Baden: ["2500"],
      "Bruck an der Leitha": ["2460"],
      Gänserndorf: ["2230"],
      Gmünd: ["3950"],
      Hollabrunn: ["2020"],
      Horn: ["3580"],
      Korneuburg: ["2100"],
      "Krems an der Donau": ["3500"],
      Lilienfeld: ["3180"],
      Melk: ["3390"],
      Mistelbach: ["2130"],
      Mödling: ["2340"],
      Neunkirchen: ["2620"],
      "Sankt Pölten": ["3100", "3104"],
      Scheibbs: ["3270"],
      Tulln: ["3430"],
      "Waidhofen an der Thaya": ["3830"],
      "Wiener Neustadt": ["2700", "2704"],
      Zwettl: ["3910"],
    },
  },
  Oberösterreich: {
    cities: {
      "Braunau am Inn": {
        Bezirke: ["Braunau am Inn"],
      },
      Eferding: {
        Bezirke: ["Eferding"],
      },
      Freistadt: {
        Bezirke: ["Freistadt"],
      },
      Gmunden: {
        Bezirke: ["Gmunden"],
      },
      Linz: {
        Bezirke: ["Linz", "Linz-Land"],
      },
      Perg: {
        Bezirke: ["Perg"],
      },
      "Ried im Innkreis": {
        Bezirke: ["Ried im Innkreis"],
      },
      Rohrbach: {
        Bezirke: ["Rohrbach"],
      },
      Schärding: {
        Bezirke: ["Schärding"],
      },
      Steyr: {
        Bezirke: ["Steyr", "Steyr-Land"],
      },
      "Urfahr-Umgebung": {
        Bezirke: ["Urfahr-Umgebung"],
      },
      Vöcklabruck: {
        Bezirke: ["Vöcklabruck"],
      },
      Wels: {
        Bezirke: ["Wels", "Wels-Land"],
      },
    },
    postal_codes: {
      "Braunau am Inn": ["5280"],
      Eferding: ["4070"],
      Freistadt: ["4240"],
      Gmunden: ["4810"],
      Linz: ["4020", "4030"],
      Perg: ["4320"],
      "Ried im Innkreis": ["4910"],
      Rohrbach: ["4150"],
      Schärding: ["4780"],
      Steyr: ["4400", "4407"],
      "Urfahr-Umgebung": ["4050"],
      Vöcklabruck: ["4840"],
      Wels: ["4600", "4603"],
    },
  },
  Salzburg: {
    cities: {
      Hallein: {
        Bezirke: ["Hallein"],
      },
      Salzburg: {
        Bezirke: ["Salzburg", "Salzburg-Umgebung"],
      },
      "St. Johann im Pongau": {
        Bezirke: ["Sankt Johann im Pongau"],
      },
      Tamsweg: {
        Bezirke: ["Tamsweg"],
      },
      "Zell am See": {
        Bezirke: ["Zell am See"],
      },
    },
    postal_codes: {
      Hallein: ["5400"],
      Salzburg: ["5020", "5061"],
      "St. Johann im Pongau": ["5600"],
      Tamsweg: ["5580"],
      "Zell am See": ["5700"],
    },
  },
  Steiermark: {
    cities: {
      "Bruck an der Mur": {
        Bezirke: ["Bruck an der Mur"],
      },
      Deutschlandsberg: {
        Bezirke: ["Deutschlandsberg"],
      },
      Graz: {
        Bezirke: ["Graz", "Graz-Umgebung"],
      },
      Hartberg: {
        Bezirke: ["Hartberg-Fürstenfeld"],
      },
      Judenburg: {
        Bezirke: ["Murtal"],
      },
      Leibnitz: {
        Bezirke: ["Leibnitz"],
      },
      Leoben: {
        Bezirke: ["Leoben"],
      },
      Liezen: {
        Bezirke: ["Liezen"],
      },
      Murau: {
        Bezirke: ["Murau"],
      },
      Mürzzuschlag: {
        Bezirke: ["Bruck-Mürzzuschlag"],
      },
      Voitsberg: {
        Bezirke: ["Voitsberg"],
      },
      Weiz: {
        Bezirke: ["Weiz"],
      },
    },
    postal_codes: {
      "Bruck an der Mur": ["8600"],
      Deutschlandsberg: ["8530"],
      Graz: ["8010", "8020"],
      Hartberg: ["8230"],
      Judenburg: ["8750"],
      Leibnitz: ["8430"],
      Leoben: ["8700"],
      Liezen: ["8940"],
      Murau: ["8850"],
      Mürzzuschlag: ["8680"],
      Voitsberg: ["8570"],
      Weiz: ["8160"],
    },
  },
  Tirol: {
    cities: {
      Imst: {
        Bezirke: ["Imst"],
      },
      Innsbruck: {
        Bezirke: ["Innsbruck", "Innsbruck-Land"],
      },
      Kitzbühel: {
        Bezirke: ["Kitzbühel"],
      },
      Kufstein: {
        Bezirke: ["Kufstein"],
      },
      Landeck: {
        Bezirke: ["Landeck"],
      },
      Lienz: {
        Bezirke: ["Lienz"],
      },
      Reutte: {
        Bezirke: ["Reutte"],
      },
      Schwaz: {
        Bezirke: ["Schwaz"],
      },
    },
    postal_codes: {
      Imst: ["6460"],
      Innsbruck: ["6020", "6060"],
      Kitzbühel: ["6370"],
      Kufstein: ["6330"],
      Landeck: ["6500"],
      Lienz: ["9900"],
      Reutte: ["6600"],
      Schwaz: ["6130"],
    },
  },
  Vorarlberg: {
    cities: {
      Bludenz: {
        Bezirke: ["Bludenz"],
      },
      Bregenz: {
        Bezirke: ["Bregenz"],
      },
      Dornbirn: {
        Bezirke: ["Dornbirn"],
      },
      Feldkirch: {
        Bezirke: ["Feldkirch"],
      },
    },
    postal_codes: {
      Bludenz: ["6700"],
      Bregenz: ["6900"],
      Dornbirn: ["6850"],
      Feldkirch: ["6800"],
    },
  },
  Wien: {
    cities: {
      Wien: {
        Bezirke: [
          "Innere Stadt",
          "Leopoldstadt",
          "Landstraße",
          "Wieden",
          "Margareten",
          "Mariahilf",
          "Neubau",
          "Josefstadt",
          "Alsergrund",
          "Favoriten",
          "Simmering",
          "Meidling",
          "Hietzing",
          "Penzing",
          "Rudolfsheim-Fünfhaus",
          "Ottakring",
          "Hernals",
          "Währing",
          "Döbling",
          "Brigittenau",
          "Floridsdorf",
          "Donaustadt",
          "Liesing",
        ],
      },
    },
    postal_codes: {
      Wien: [
        "1010",
        "1020",
        "1030",
        "1040",
        "1050",
        "1060",
        "1070",
        "1080",
        "1090",
        "1100",
        "1110",
        "1120",
        "1130",
        "1140",
        "1150",
        "1160",
        "1170",
        "1180",
        "1190",
        "1200",
        "1210",
        "1220",
        "1230",
      ],
    },
  },
};
