import { ServicoParagraph } from "../../components/Servico/ServicoStyled";

export interface AboutUsInterface {
  title: string;
  description: () => React.ReactElement;
}

export const AboutUsEN: AboutUsInterface = {
  title: "About us",
  description: () => (
    <>
      <ServicoParagraph className={"my-2"}>
        Introducing Servi.co, a dedicated intermediary platform committed to
        fostering meaningful connections between those in search of diverse
        services and skilled service providers. Our foremost goal is to simplify
        the search for proficient professionals, ensuring an optimal blend of
        quality and pricing. At Servi.co, we wholeheartedly devote ourselves to
        empowering users by offering a diverse range of experts across various
        fields.
      </ServicoParagraph>
      <ServicoParagraph>
        Servi.co emerges with a singular mission: to instill order in the
        service industry and provide a secure, user-friendly platform for
        discovering service providers. Acknowledging the challenges associated
        with identifying qualified individuals for everyday service needs, we
        have embarked on a mission to transform this process into a positive and
        seamless experience for our users. Guided by the core values of
        transparency, quality, speed, and fair pricing, our unwavering focus is
        on elevating efficiency at every stage – from effortlessly creating a
        job application to facilitating seamless communication with potential
        service providers, ultimately resulting in the successful selection of
        an expert at a fair and competitive price.
      </ServicoParagraph>
    </>
  ),
};

export const AboutUsDE: AboutUsInterface = {
  title: "Über uns",
  description: () => (
    <>
      <ServicoParagraph className={"my-2"}>
        Wir stellen Ihnen Servi.co vor, eine spezielle Vermittlungsplattform,
        die sich der Förderung sinnvoller Verbindungen zwischen Menschen, die
        auf der Suche nach vielfältigen Dienstleistungen sind, und
        qualifizierten Dienstleistern widmet. Unser oberstes Ziel ist es, die
        Suche nach kompetenten Fachkräften zu vereinfachen und eine optimale
        Mischung aus Qualität und Preis sicherzustellen. Bei Servi.co widmen wir
        uns voll und ganz der Stärkung der Benutzer, indem wir ein vielfältiges
        Spektrum an Experten aus verschiedenen Bereichen anbieten.
      </ServicoParagraph>
      <ServicoParagraph>
        Servi.co entsteht mit einer einzigartigen Mission: Ordnung in der
        Dienstleistungsbranche zu schaffen und eine sichere, benutzerfreundliche
        Plattform für die Suche nach Dienstleistern bereitzustellen. Wir sind
        uns der Herausforderungen bewusst, die mit der Identifizierung
        qualifizierter Personen für den täglichen Servicebedarf verbunden sind,
        und haben es uns zur Aufgabe gemacht, diesen Prozess in ein positives
        und nahtloses Erlebnis für unsere Benutzer zu verwandeln. Geleitet von
        den Grundwerten Transparenz, Qualität, Schnelligkeit und faire Preise
        liegt unser unerschütterlicher Fokus auf der Steigerung der Effizienz in
        jeder Phase – von der mühelosen Erstellung einer Bewerbung über die
        Erleichterung einer nahtlosen Kommunikation mit potenziellen
        Dienstleistern bis hin zur erfolgreichen Auswahl eines Experte zu einem
        fairen und wettbewerbsfähigen Preis.
      </ServicoParagraph>
    </>
  ),
};
