import { AuthActionTypes } from "../auth/constants";

export interface ILocalStorage {
  user: IUserData;
}

export interface IAuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER;
  payload: {} | string;
}

export interface IUserData {
  userId: number;
  createdAt: Date;
  updatedAt: Date;
  type?: EnumUserType;
  email: string;
  password: string;
  name: string;
  address?: IAddress;
  userPublicId?: string;
  phoneNumber?: string;
  profilePicture?: string;
  birthDate?: Date;
  accountConfirmed?: boolean;
  tempPassword?: string;
  categories?: string[];
  ortSelektiert?: string[];
  subscriptionType?: EnumSubscriptionType;
  subscriptionStartDate?: Date;
  subscriptionEndDate?: Date;
  subscriptionStatus?: EnumSubscriptionStatus;
  favourites?: string[];
  communications?: ICommunication[];
  sessionId?: string;
  menuAccessRights?: IMenuAccessRightsUser | null;
  termsConditions?: boolean;
  serviceCompany?: boolean;
  serviceCompanyName?: string;
}

export enum EnumUserType {
  SUPPLIER = "SUPPLIER",
  CLIENT = "CLIENT",
}

export interface IAddress {
  postalCode: string;
  country: EnumCountry;
  cityId: string;
  districtId?: number;
  street?: string;
  elevator?: boolean;
  stairs?: string;
  floor?: string;
  apartmentNumber?: string;
}

export enum EnumCountry {
  AUSTRIA = "AUSTRIA",
}

export interface ISupplierCategories {
  // TODO: Define Categories
}

export enum EnumSubscriptionType {
  BASIC1 = "BASIC1",
  BASIC3 = "BASIC3",
  BASIC6 = "BASIC6",
  BASIC12 = "BASIC12",
  PHONEBOOK1 = "PHONEBOOK1",
  PHONEBOOK3 = "PHONEBOOK3",
  PHONEBOOK6 = "PHONEBOOK6",
  PHONEBOOK12 = "PHONEBOOK12",
  BUNDLE1 = "BUNDLE1",
  BUNDLE3 = "BUNDLE3",
  BUNDLE6 = "BUNDLE6",
  BUNDLE12 = "BUNDLE12",
}

export enum EnumSubscriptionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

// ---- COMMUNICATION ----
// Amout of the discussed fee between users
export interface INegotiation {
  offers: IFeeItem[];
  jobPrice: number;
}

export interface IFeeItem {
  clientOffer: number;
  clientMessage: string;
  supplierOffer: number;
  supplierMessage: string;
  clientAccepted: boolean;
  supplierAccepted: boolean;
}

export enum EnumCommunicationStatus {
  PENDING = "pending",
  CANCELLED = "cancelled",
  AGREED = "agreed",
  CLOSED = "closed",
}

export interface ICommunication {
  communicationId: number;
  createdAt: Date;
  updatedAt: Date;
  fromUser: number;
  toUser: number;
  content: string;
  sentAt: Date;
  title?: string;
  active?: boolean;
  fileType?: boolean;
  read?: boolean;
  fromUserPublicId: string;
}

export interface Offer {
  offerId: number;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  title: string;
  description: string;
  boostType?: EnumOfferBoostType;
  category?: EnumOfferCategory;
  boostEndDate?: Date;
  boostStartDate?: Date;
  boostPayed?: boolean;
  boostInvoice?: string[];
  images?: string[];
  paymentStatus?: boolean;
  paymentReceived?: boolean;
  fee?: number;
  jobPrice?: number;
  invoicePayment?: string[];
  invoiceFee?: string[];
  reviewClient?: string;
  reviewSupplier?: string;
  descriptionReviewClient?: string;
  descriptionReviewSupplier?: string;
  offerStatus?: EnumOfferStatus;
  communications?: ICommunication[];
}

// ---- OFFER ----
export enum EnumOfferCategory {
  // House and Office help
  HouseCleaning = "HouseCleaning",
  ApartmentCleaning = "ApartmentCleaning",
  OfficeCleaning = "OfficeCleaning",
  Ironing = "Ironing",
  HouseOfficeHelpOther = "HouseOfficeHelpOther",

  // House works
  Painting = "Painting",
  ElectricalService = "ElectricalService",
  ElectricalAppliances = "ElectricalAppliances",
  FittingAssemblingDissembling = "FittingAssemblingDissembling",
  BathroomSanitary = "BathroomSanitary",
  Plumbing = "Plumbing",
  CeramicWorks = "CeramicWorks",
  GroundWork = "GroundWork",
  HouseWorksOther = "HouseWorksOther",

  // Garden and landscape design
  Mowing = "Mowing",
  Trimming = "Trimming",
  RootMiling = "RootMiling",
  FenceConstruction = "FenceConstruction",
  Earthworks = "Earthworks",
  GardenCleaning = "GardenCleaning",
  GardenRenovation = "GardenRenovation",
  GardenLandscapeDesignOther = "GardenLandscapeDesignOther",

  // Animal care
  DogCare = "DogCare",
  CatCare = "CatCare",
  AnimalCareOther = "AnimalCareOther",

  // Moving and Transportation
  StuffMoving = "StuffMoving",
  PeopleTransport = "PeopleTransport",
  MovingTransportationOther = "MovingTransportationOther",

  // Renovation
  HouseRenovation = "HouseRenovation",
  ApartmentRenovation = "ApartmentRenovation",
  OfficeRenovation = "OfficeRenovation",
  RenovationOther = "RenovationOther",

  // Beauty
  Makeup = "Makeup",
  ManicurePedicure = "ManicurePedicure",
  BeautyOther = "BeautyOther",

  // Tutoring
  Tutoring = "Tutoring",

  // General Other
  Other = "Other",
}

export enum EnumOfferBoostType {
  STANDARD = "STANDARD",
  PREMIUM = "PREMIUM",
}

export enum EnumOfferStatus {
  OPEN = "OPEN",
  PAYMENTPENDING = "PAYMENTPENDING",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  AGREED = "AGREED",
  CLOSED = "CLOSED",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}

// User Registration
export interface IRegisterUserInterface {
  email: string;
  password: string;
  repeatPassword: string;
  name: string;
  phoneNumber?: string;
  oldEnough?: boolean;
  description?: string;
  // Address information
  postalCode?: string;
  country?: EnumCountry;
  cityId?: string;
  districtId?: number;
  street?: string;
  elevator?: boolean;
  stairs?: string;
  floor?: string;
  apartmentNumber?: string;
  // Payment information
  profilePicture?: FileList;
  birthDate?: Date;
  categories?: Array<categoryType>;
  ortSelektiert?: IPLaceService[];
  termsConditions?: boolean;
  serviceCompany?: boolean;
  serviceCompanyName?: string;
  type: accountTypeType;
}

interface IPLaceService {
  label: string;
  value: string;
}

export type accountTypeType = "CLIENT" | "SUPPLIER" | undefined;
export type categoryType = { label: string; value: string };

// Def. Account Type
export enum AccounType {
  CLIENT = "CLIENT",
  SUPPLIER = "SUPPLIER",
}

export interface IMenuAccessRightsUser {
  links: ILinkMenu[];
}

export interface ILinkMenu {
  pageName: EnumPageName;
  subPages: EnumSubPageName[];
}

export enum EnumPageName {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum EnumSubPageName {
  HOME = "HOME",
  FAQ = "FAQ",
  EDITACCOUNT = "EDITACCOUNT",
  MESSAGES = "MESSAGES",
  CREATIONPAGE = "CREATIONPAGE",
  OFFERPAGE = "OFFERPAGE",
  PROFILEPAGE = "PROFILEPAGE",
  SEARCHPAGE = "SEARCHPAGE",
  EDITOFFERPAGE = "EDITOFFERPAGE",
  CHAT = "CHAT",
  PRICING = "PRICING",
  ABOUTUS = "ABOUTUS",
}

export interface IOffer {
  offerId: number;
  title: string;
  description: string;
  category: EnumOfferCategory;
  images: string[];
  offerPublicId: string;
  createdAt: string;
  userId: number;
  approved: boolean;
  showNumber: boolean;
  address: IAddress;
}

interface IOfferWithUser {
  offer: IOffer;
  user?: IUserData; // Optional since it's nullable in the GraphQL Object Type
}

export interface LatestOffersResponse {
  LatestOffers: IOfferWithUser[];
}

export interface RandomOffersResponse {
  RandomOffers: IOfferWithUser[];
}

export interface OffersByCategoryResponse {
  FilteredOffers: IOfferWithUser[];
}

export interface OffersByUserIdResponse {
  OffersByUser: IOfferWithUser[];
}

export interface ArchivedOffersResponse {
  getArchivedOffers: IOfferWithUser[];
}

export interface OfferItemResponse {
  OfferItem: IOfferWithUser;
}

export interface INTERFACE_GET_CHATS_BY_USER_ID {
  getChatsByUserId: IChat[];
}

export interface IChat {
  chatId: string;
  chatPublicId: string;
  clientPublicId: string;
  supplierPublicId: string;
  clientPublicIdDeleted: boolean;
  supplierPublicIdDeleted: boolean;
  clientSeen: boolean;
  supplierSeen: boolean;
  communications: ICommunication[];
  clientUser: IUserData;
  supplierUser: IUserData;
  offer?: IOffer;
}

export interface IChatArea {
  chatId: string;
  chatPublicId: string;
  clientPublicId: string;
  supplierPublicId: string;
  clientPublicIdDeleted: boolean;
  supplierPublicIdDeleted: boolean;
  clientSeen: boolean;
  supplierSeen: boolean;
  communications: ICommunication[];
  clientUser: IUserData;
  supplierUser: IUserData;
  offer?: IOffer;
  lastDecryptedMessage?: string;
}

export enum EnumNotificationType {
  MESSAGE = "MESSAGE",
  REVIEW = "REVIEW",
  POSTONE = "POSTONE",
  POSTTWO = "POSTTWO",
}

export interface INotification {
  notificationId: number;
  createdAt: Date;
  updatedAt: Date;
  type?: EnumNotificationType;
  seen?: boolean;
  User?: IUserData;
}
